import React, { useContext, useEffect, useState } from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    Collapse,
    List,
    Avatar, Typography, Divider
} from '@material-ui/core';
import {
    ArrowBack as ArrowBackIcon,
    ExpandLess,
    ExpandMore,
    HelpOutline,
    LiveHelpOutlined,
    Settings
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx'
import menuData, { getPushPathWrapper } from '../../routes'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import Context from '../../layouts/context'
import _ from 'lodash'
import { LogoutOutlined } from '@ant-design/icons';
import { assetRoutePath } from '../../settings';

export default function DrawerMenu(props) {
    const { currentUser } = props
    const dispatch = useDispatch()
    const redux = useContext(Context)
    const useStyles = makeStyles(theme => ({
        menu: {
            height: '100vh',
            position: 'fixed',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.palette.base.main,
            overflowY: 'auto',
            overflowX: 'hidden'
            // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        absolute: {
            position: 'absolute'
        },
        drawer: {
            flexShrink: 0,
            height: '100%'
        },
        selected: {
            backgroundColor: theme.palette.primary.dark,
            color: '#4285F4'
        },
        back: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '50px',
        },
        backIcon: {
            padding: '18px',
            height: '50px',
            width: '50px',
            "&:hover": {
                background: "#efefef59"
            }
        },
        fullList: {
            width: 'auto',
        },
        drawerPaper: {
            position: 'relative',
            whiteSpace: 'nowrap',
            maxWidth: redux.maxSideMenuWidth,
            minWidth: redux.maxSideMenuWidth,
            backgroundColor: theme.palette.base.main,

            border: 'unset',
            zIndex: 12,
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            borderRight: 'unset',
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: 0
        },
        head: {
            // display: 'flex',
            // alignItems: 'center',
            // padding: '1em',
            // paddingLeft: '1.5rem',
            paddingTop: `${redux.isMobile ? '3rem' : '2rem'}`,
            marginBottom: `${redux.isMobile ? '0' : '3rem'}`
        },
        flexColumn: {
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'unset'
        },
        avatar: {
            width: theme.spacing(redux.isMobile ? 7 : 4),
            height: theme.spacing(redux.isMobile ? 7 : 4),
        },
        nested: {
            paddingLeft: '40px'
        },
        topMargin: {
            marginTop: `${redux.navBarHeight}px`
        },
        leftPadding: {
            paddingLeft: '1.5rem'
        },
        menuWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            paddingBottom: '1rem'
        }
    }));
    const classes = useStyles();
    const [groupedAssetList, setGroupedAssetList] = useState({ bankAccount: [], investment: [] });
    // const [open, setOpen] = useState(false);
    const [isPathOpen, setPathOpen] = useState([]);
    const { assetList } = useSelector((state) => ({
        assetList: state.asset.assetList
    }))

    useEffect(() => {
        let newGroupedAssetList = _.groupBy(assetList, 'asset_type')
        setGroupedAssetList(() => newGroupedAssetList)
    }, [assetList])

    const handleClick = (index) => {
        let newArr = _.clone(isPathOpen)
        newArr[index] = !newArr[index]
        setPathOpen((prevArr) => newArr);
    };
    const pushOn = (path) => {
        dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
        dispatch(push(path))
    }

    const logout = (e) => {
        dispatch({ type: 'SET_LOGIN_RESPONSE', payload: null })
        dispatch({ type: 'LOGOUT' })
    }

    const mainListItems = (
        <div>
            {
                menuData.map((item, index) => ((!item.dontShowOnMenu && !(item.key === 'settings' && redux.isMobile)) && <>
                    <ListItem key={item.key} button component='a' className={clsx(classes.leftPadding, item.path === window.location.pathname && classes.selected)} onClick={(e) => {
                        e.preventDefault()
                        if (!item.children)
                            pushOn(item.path)
                        else
                            handleClick(index)
                    }}>
                        <ListItemIcon className={clsx(item.path === window.location.pathname && classes.selected)}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                        {item.children && (!isPathOpen[index] || (item.path === window.location.pathname) ? <ExpandLess color='disabled' /> : <ExpandMore color='disabled' />)}
                    </ListItem>
                    <Collapse in={!isPathOpen[index]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(item.key === 'investment' || item.key === 'bankAccount') ?
                                groupedAssetList[item.key]?.map(child => {
                                    let childPath = assetRoutePath + child.id
                                    return (<ListItem key={child.id} button component='a' className={clsx(classes.nested, childPath === window.location.pathname && classes.selected)} onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(getPushPathWrapper('asset', { id: child.id }))

                                        // pushOn(childPath)
                                    }}>
                                        <ListItemIcon className={clsx(childPath === window.location.pathname && classes.selected)}>
                                            {child.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={child.name} />
                                    </ListItem>)
                                }) :
                                item.children?.map(child => !child.dontShowOnMenu &&
                                    <ListItem key={child.key} button component='a' className={clsx(classes.nested, child.path === window.location.pathname && classes.selected)} onClick={(e) => {
                                        e.preventDefault()
                                        if (!child.children)
                                            pushOn(child.path)
                                        else
                                            handleClick(index)
                                    }}>
                                        <ListItemIcon className={clsx(child.path === window.location.pathname && classes.selected)}>
                                            {child.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={child.name} />
                                    </ListItem>)
                            }
                        </List>
                    </Collapse>
                </>
                ))
            }
        </div>
    );

    const renderDesktopUser = () => (<>
        <ListItem button component='a' className={clsx(classes.leftPadding)} onClick={(e) => {
            e.preventDefault()
            handleClick(30)
        }}>
            <ListItemIcon>
                <Avatar variant="rounded" src={currentUser && currentUser['profile_pic']} className={classes.avatar}></Avatar>
            </ListItemIcon>
            <ListItemText primary={currentUser && currentUser.name} />
            {/* {(isPathOpen[30] ? <ExpandLess color='disabled' /> : <ExpandMore color='disabled' />)} */}
        </ListItem>
        {/* <Collapse in={isPathOpen[30]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem button component='a' className={clsx(classes.nested)} onClick={logout}>
                    <ListItemIcon>
                        <LogoutOutlined />
                    </ListItemIcon>
                    <ListItemText primary={'Log out'} />
                </ListItem>
            </List>
        </Collapse> */}
    </>)

    const renderMobileUser = () => (<div className={classes.leftPadding}>
        <Avatar className={classes.avatar} src={currentUser && currentUser['profile_pic']} />
        <Typography variant='subtitle1' style={!redux.isMobile ? { margin: '1rem' } : { margin: '1rem 0' }}>
            {currentUser && currentUser.name}
        </Typography>
    </div>)

    return (
        <div id='basemenu' className={clsx({ [classes.menu]: true })}>
            <Drawer open={redux.isMobile ? redux.collapsed : true}
                onClose={() => dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })}
                className={classes.drawer}
                variant={redux.isMobile ? "temporary" : 'permanent'}
                classes={{
                    paper: clsx(classes.drawerPaper),
                }}
            ><div className={classes.menuWrapper}>
                    <div>
                        <div className={clsx({ [classes.head]: true, [classes.topMargin]: !redux.isMobile, [classes.flexColumn]: redux.isMobile })}>
                            {redux.isMobile ? renderMobileUser() : renderDesktopUser()}
                        </div>
                        <List disablePadding>{mainListItems}</List>
                    </div>
                    {redux.isMobile ? <div>
                        {/* <ListItem button component='a' className={clsx(classes.leftPadding)} onClick={(e) => {
                            e.preventDefault()
                        }}>
                            <ListItemIcon>
                                <Settings />
                            </ListItemIcon>
                            <ListItemText primary='Settings' />
                        </ListItem> */}
                        <ListItem button component='a' className={clsx(classes.leftPadding)} target='__blank' href='https://www.exirio.com/faq' >
                            <ListItemIcon>
                                <LiveHelpOutlined />
                            </ListItemIcon>
                            <ListItemText primary='FAQ' />
                        </ListItem>
                        <ListItem button component='a' className={clsx(classes.leftPadding)} target='__blank' href='mailto:support@exirio.com?subject=Exirio: Help & Feedback' >
                            <ListItemIcon>
                                <HelpOutline />
                            </ListItemIcon>
                            <ListItemText primary='Help & Feedback' />
                        </ListItem>
                        <Divider />
                        <ListItem button component='a' className={clsx(classes.leftPadding)} onClick={logout}>
                            <ListItemIcon>
                                <img src='/logOutIcon.svg' />
                            </ListItemIcon>
                            <ListItemText primary='Log Out' />
                        </ListItem>

                    </div> :
                        <div>
                            <ListItem button component='a' className={clsx(classes.leftPadding)} target='__blank' href='https://www.exirio.com/faq' >
                                <ListItemIcon>
                                    <LiveHelpOutlined />
                                </ListItemIcon>
                                <ListItemText primary='FAQ' />
                            </ListItem>
                            <ListItem button component='a' className={clsx(classes.leftPadding)} target='__blank' href='mailto:support@exirio.com?subject=Exirio: Help & Feedback' >
                                <ListItemIcon>
                                    <HelpOutline />
                                </ListItemIcon>
                                <ListItemText primary='Help & Feedback' />
                            </ListItem>
                        </div>
                    }
                </div>
            </Drawer>
        </div>
    );
}