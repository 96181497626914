import React, { useEffect, Suspense } from 'react'
import { Route, Link, Redirect, Switch } from 'react-router-dom'
import menuData from './routes'
import BasicLayoutWrapper from './layouts/BasicLayout'
import Login from './containers/login/views'
import GoogleLoginCallback from './containers/login/views/googleLoginCallback'
import FbLoginCallbcak from './containers/login/views/fbLoginCallback'
import MsLoginCallback from './containers/login/views/msLoginCallback'
import { useDispatch, useSelector } from 'react-redux'
import { listAssetAction } from './containers/asset/actions'
import _ from 'lodash'
import AddToHomescreen from 'react-add-to-homescreen'
import Exception from './components/Exception'
const Exp = () => (<Exception
  type="404"
  desc={'You Seems lost !!'}
  linkElement={Link}
  redirect={'/dashboard'}
  backText={'Go To Homepage?'}
/>)

export default (props) => {
  const dispatch = useDispatch()
  const { token, currentUser, assetList } = useSelector(state => ({
    currentUser: state.global.currentUser,
    token: state.global.token,
    assetList: state.asset.assetList || []
  }))

  useEffect(() => {
    if (currentUser && token)
      dispatch(listAssetAction())
  }, [token, currentUser])

  const handleAddToHomescreenClick = () => {
    alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`)
  }

  const assetMenu = _.find(menuData, (e) => e.key === 'asset')

  return (<Suspense fallback={<div>Loading...</div>}>
    <Switch>
      {/* <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} /> */}

      {menuData.map((item, key) => {
        if (!item.children) {
          return (<Route exact path={item.path} key={item.path} render={(route) => {
            return <BasicLayoutWrapper component={item.component} item={item} menuData={menuData} />
          }} />)
        }
      })}

      {menuData.map((item, key) => {
        if (item.children) {
          return item.children.map((child, k) => {
            return (<Route exact path={child.path} key={child.path} render={(route) => {
              return <BasicLayoutWrapper component={child.component} item={item}  /* user={user} */
                menuData={menuData} />
            }} />)
          })
        }
      })}

      {/* Asset Routes  */}

      {/* {assetList.map((item, key) => {
        let assetPath = `/asset/${item.id}`
        return (<Route exact path={assetPath} key={assetPath} render={(route) => {
          return <BasicLayoutWrapper component={assetMenu.component} item={assetMenu} menuData={menuData} />
        }} />)
      })} */}

      <Route exact path="/login" render={(route) => {
        return (
          <Login />
        )
      }} />
      <Route exact path="/signup" render={(route) => {
        return (
          <Login signup={true} />
        )
      }} />
      <Route exact path="/login/google/callback" render={(route) => {
        return (
          <GoogleLoginCallback />
        )
      }} />
      <Route exact path="/login/fb/callback" render={(route) => {
        return (
          <FbLoginCallbcak />
        )
      }} />
      <Route exact path="/login/ms/callback" render={(route) => {
        return (
          <MsLoginCallback />
        )
      }} />
      <Route exact path="/" render={(route) => {
        return (
          <Redirect to="/dashboard" />
        )
      }} />
      <Route component={Exp} />


    </Switch>
  </Suspense>)
};