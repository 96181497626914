
export default (
    state = {
        dashboardLoader: false,
        listData: [],
        chartData: {},
        chartMinMonth: 12,
        summaryData: {
            totalXRR: {},
            totalTWR: {},
            totalNetWorth: 0,
            latestValuationDate: {},
            combinedXRR: 0,
            combinedTWR: {},
            currentValue: {},
            investedAmount: {},
            totalTransaction: 0
        }
    },
    action
) => {
    switch (action.type) {
        case "DASHBOARD_LIST":
            return { ...state, listData: action.payload };
        case "SET_DASHBOARD_CHART_DATA":
            return { ...state, chartData: action.payload };
        case 'SET_CHART_MIN_MONTH':
            return { ...state, chartMinMonth: action.payload }
        case 'SET_CURRENT_VALUE':
            return { ...state, currentValue: action.payload }
        case 'SET_SUMMARY_DATA':
            return { ...state, summaryData: action.payload }
        case 'SHOW_DASHBOARD_LOADER':
            return { ...state, dashboardLoader: true }
        case 'HIDE_DASHBOARD_LOADER':
            return { ...state, dashboardLoader: false }
        default:
            return state;
    }
};
