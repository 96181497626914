import { apiUrl } from "../../../settings";

export const addAssetUrl = () => {
    return apiUrl + '/addAsset';
};

export const listAssetUrl = () => {
    return apiUrl + '/getAssetsByUid';
};

export const getAssetByIdUrl = () => {
    return apiUrl + '/getAssetById';
};

export const getTransactionOfAssetUrl = () => {
    return apiUrl + '/getTransactionByAssetId';
};

export const editAssetUrl = () => {
    return apiUrl + '/modifyAsset';
};

export const deleteAssetUrl = () => {
    return apiUrl + '/removeAsset';
};

export const listAssetValuationUrl = () => {
    return apiUrl + '/getAllValuationByUid';
};

export const deleteAssetValuationUrl = () => {
    return apiUrl + '/removeAssetValuation';
};

export const editAssetValuationUrl = () => {
    return apiUrl + '/modifyAssetValuation';
};

export const listAssetValuationByAssetUrl = () => {
    return apiUrl + '/getValuationByAssetId';
};

export const addAssetValuationUrl = () => {
    return apiUrl + '/addAssetValuation';
};