import React, { useState, useContext, useEffect, useRef } from 'react'
import { Checkbox, CircularProgress, Divider, FormControlLabel, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import Context from '../../../layouts/context'
import clsx from 'clsx'
import { AppBar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import styles from './login.less'
import FormItem from '../../../components/FormItem'
import {
  FacebookLoginAction,
  MicrosoftLoginAction,
  GoogleLoginAction,
} from '../actions'
import line from '../../../assets/line.png'
import logo from '../../../assets/logo.png'
import wealth from '../../../assets/wealth.png'
import microsoft from '../../../assets/microsoft.png'
import apple from '../../../assets/apple.png'
import AddToHomescreen from 'react-add-to-homescreen'
import { useSnackbar } from 'notistack';
import { push } from 'connected-react-router'

const LoginPage = (props) => {

  const { signup } = props
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isMobile, loginResponse, loginLoader } = useSelector(state => ({
    isMobile: state.theme.isMobile,
    loginResponse: state.login.loginResponse,
    loginLoader: state.login.loginLoader
  }))
  const useStyles = makeStyles(theme => ({
    marginLeft: {
      marginLeft: '1rem',
    },
    marginVertical: {
      margin: '0 1rem',
    },
    appBar: {
      backgroundColor: '#006DEA',
      width: '100vw',
      // justifyContent: 'center',
      height: '12rem',
      boxShadow: 'unset',
      backgroundImage: 'url(/images/vectorBgSmall.png)',
      display: 'flex',
      justifyContent: 'space-evenly',
      padding: '10px',
      position: 'relative',

    },
    checkBox: {
      color: '#B9BDCA',
    },
    navLogo: {
      fontWeight: 'bolder',
      fontFamily: 'sans-serif',
      fontSize: '28px'
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center'
    },
    divider: {
      height: "1px",
      width: "50px",
      margin: "0 1rem",
      backgroundColor: "#ffffff4a"
    },
    loader: {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255,255,255,0.3)'
    }
  }))
  const classes = useStyles()
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  })

  const handleAddToHomescreenClick = () => {
    alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`)
  }

  useEffect(()=>{
    if (loginResponse && loginResponse.error) {
      enqueueSnackbar('Login failed.', {
        variant: 'error',
      });
    }
    dispatch({ type: 'SET_LOGIN_RESPONSE', payload: null })

  },[loginResponse])

  const facebookLogin = () => {
    dispatch(FacebookLoginAction())
  }
  const microsoftLogin = () => {
    dispatch(MicrosoftLoginAction())
  }
  const googleLogin = () => {
    dispatch(GoogleLoginAction())
  }
  const onChange = (prop) => (e) => {
    // e.preventDefault()
    // e.persist()
    // console.log(prop, e, e.target.value, values)
    // setValues(() => ({ ...values, [prop]: e.target.value }))
  }

  const SignupView = () => {
    return (
      <>
        <FormItem
          // value={values.firstName}
          id={'firstName'}
          label={'First Name - Feature not implemented, use social login'}
          handleChange={onChange('firstName')}
        />
        <FormItem id={'lastName'} value={values.lastName} label={'Last Name - Feature not implemented, use social login'} handleChange={onChange('lastName')} />
        <FormItem id={'email'} value={values.email} label={'Email Address - Feature not implemented, use social login'} handleChange={onChange('email')} />
        <FormItem inputType={'PASSWORD'} id={'password'} label={'Password - Feature not implemented, use social login'} value={values.password}
          showPassword={values.showPassword} handleChange={onChange('password')} />
        <div className={styles.lower} />
        <FormItem inputType={'LOGINBUTTON'} label={'REGISTER'} className={styles.button} />
      </>
    )
  }
  const SignInView = () => {
    return (
      <>
        <FormItem id={'email'} label={'Email Address - Feature not implemented, use social logins below'} handleChange={onChange('email')} />
        <FormItem inputType={'PASSWORD'} id={'password'} label={'Password - Feature not implemented, use social logins below'} className={styles.password}
          value={values.password} showPassword={values.showPassword} handleChange={onChange('password')} />
        <div className={styles.lower}>
          {/* <FormItem inputType={'CHECKBOX'} label={'Remember Me'} /> */}
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                // checked={state.checkedB}
                // handleChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Remember me"
          />
          <div className={styles.forget}>Forgot Password?</div>

        </div>

        <FormItem inputType={'LOGINBUTTON'} label={'LOGIN'} className={styles.button} />
      </>
    )
  }
  const MobileSignUpView = () => {
    return (
      <div className={styles.mobileView}>
        <div className={styles.mobileForm}>
          <FormItem id={'firstName'} label={'First Name'} handleChange={onChange('firstName')}
            className={styles.mobileInput} backgroundFill={true} value={values.firstName} />
          <FormItem id={'lastName'} label={'Last Name'} handleChange={onChange('lastName')}
            className={styles.mobileInput} className={styles.mobileSignup} backgroundFill={true}
            value={values.lastName} />

          <FormItem id={'email'} label={'Email Address - Feature not implemented, use social login'} handleChange={onChange('email')} className={styles.mobileInput}
            className={styles.mobileSignup} backgroundFill={true} value={values.email} />

          <FormItem inputType={'PASSWORD'} handleChange={onChange('password')} id={'password'} label={'Password - Feature not implemented, use social login'}
            className={styles.password} value={values.password} showPassword={values.showPassword}
            backgroundFill={true} />
          <div className={styles.lower}>

          </div>
          <FormItem inputType={'LOGINBUTTON'} label={'REGISTER'} className={styles.button} />
          <div className={styles.orLogin}>
            <img src={line} style={{ padding: '10px' }}></img>
            <span>or Sign Up Using</span>
            <img src={line} style={{ padding: '10px' }}></img>


          </div>
          <div className={styles.register}>
          <a href='#' onClick={facebookLogin}>
                <img src={'./images/facebook.png'}
                  style={{ position: 'relative', width: '44px', cursor: 'pointer' }} onClick={facebookLogin} />
              </a>
              <a href='#' onClick={googleLogin}>
                <img src={'/images/GoogleIcon.png'} onClick={googleLogin} className={styles.circleIcon} />
              </a>
              <a href='#' onClick={microsoftLogin}>
                <img src={microsoft} className={styles.circleIcon} onClick={microsoftLogin} />
              </a>
              {/* <a href='#'>
                <img src={apple} />
              </a> */}
          </div>
        </div>
        <div className={styles.mobileBottom}>
          Already have an account?
          <FormItem inputType={'BUTTON'} label={'LOGIN'} onClick={()=>{
            dispatch(push('/login'))
          }} className={styles.mobileButton} />

        </div>
        <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />


      </div>
    )
  }
  const MobileSignInView = () => {
    return (
      <div className={styles.mobileView}>
        <div className={styles.mobileForm}>
          <FormItem id={'email'} label={'Email Address - Feature not implemented, use social login'} className={styles.mobileInput}
            handleChange={onChange('email')} />


          <FormItem inputType={'PASSWORD'} id={'password'} label={'Password - Feature not implemented, use social login'} className={styles.password}
            value={values.password} showPassword={values.showPassword} handleChange={onChange('password')} />
          <div className={styles.lower} />
          <div className={styles.lower}>
            {/* <FormItem inputType={'CHECKBOX'} label={'Remember Me'} /> */}
            <FormControlLabel
              className={classes.checkBox}
              control={
                <Checkbox
                  // checked={state.checkedB}
                  // onChange={handleChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Remember Me"
            />
            <div className={styles.forget}>Forget Password?</div>

          </div>
          <FormItem inputType={'LOGINBUTTON'} label={'LOGIN'} className={styles.button} />
          <div className={styles.orLogin}>
          <Divider className={classes.divider} />
            {/* <img src={line} style={{ padding: '10px' }}></img> */}
            <span>or Login Using</span>
            <Divider className={classes.divider} />
            {/* <img src={line} style={{ padding: '10px' }}></img> */}


          </div>
          <div className={styles.register}>
          <a href='#' onClick={facebookLogin}>
                <img src={'./images/facebook.png'}
                  style={{ position: 'relative', width: '44px', cursor: 'pointer' }} onClick={facebookLogin} />
              </a>
              <a href='#' onClick={googleLogin}>
                <img src={'/images/GoogleIcon.png'} onClick={googleLogin} className={styles.circleIcon} />
              </a>
              <a href='#' onClick={microsoftLogin}>
                <img src={microsoft} className={styles.circleIcon} onClick={microsoftLogin} />
              </a>
              {/* <a href='#'>
                <img src={apple} />
              </a> */}
          </div>
        </div>
        <div className={styles.mobileBottom}>

          Don't have an account yet?
          <FormItem inputType={'BUTTON'} label={'CREATE AN ACCOUNT'} onClick={()=>{
            dispatch(push('/signup'))
          }} className={styles.mobileButton} />

        </div>
        <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />


      </div>
    )
  }
  const SignupBottom = () => {
    return (
      <div className={styles.bottom}>
        Already have an account? <a href='/login' style={{ color: '#006DEA', marginLeft: '5px' }}>Login</a>

      </div>
    )
  }
  const SignInBottom = () => {
    return (
      <div className={styles.bottom}>
        Don't have an account? <a href='/signup' style={{ color: '#006DEA', marginLeft: '5px' }}>Register</a>
      </div>
    )
  }
  const MobileView = () => {
    return (
      <>
        <AppBar position="fixed" className={classes.appBar}>
          <div className={styles.logosSmall}>
            <img src={'/logo.svg'} className={styles.logoSmall} />

          </div>
          {signup ? <div className={styles.title} variant='h4'>Sign Up with Us</div> : <div
            className={styles.title}>Welcome Back</div>}
          {signup ? <div variant='h4'>Create an account to continue</div> : <div>Login to continue</div>}


        </AppBar>
        {signup ? <MobileSignUpView /> : <MobileSignInView />}
      </>
    )
  }

  return (<>{
    loginLoader &&
    <div className={classes.loader}>
    <CircularProgress />
    </div>}
    {isMobile ?
      <MobileView />
      :

      <div className={signup ? styles.contentSignup : styles.content}>
        <div className={signup ? styles.mainSignup : styles.main}>
          <div className={styles.logo}>
            {/* <img src={wealth} className={styles.logos} /> */}
            <div className={classes.flexCenter}>
              <img src={logo} className={styles.wealthLogo} />
              <Typography className={classes.navLogo} varient='h4' color="primary" >
                Exirio Beta
              </Typography>
            </div>
            <Typography className={styles.logos} varient='h6' >
              {!signup ? 'Welcome back! Login to continue' : 'Create an account to continue'}
            </Typography>
          </div>
          <div className={signup ? styles.formsignup : styles.form}
          >{
              signup ?
                <SignupView />
                :
                <SignInView />
            }

            <div className={styles.orLogin}>
          <Divider className={classes.divider} />

              {/* <img src={line} ></img> */}
              <span>or login using</span>
              {/* <img src={line} style={{ padding: '10px' }}></img> */}
              <Divider className={classes.divider} />


            </div>
            <div className={styles.register}>
              <a href='#' onClick={facebookLogin}>
                <img src={'./images/facebook.png'}
                  style={{ position: 'relative', width: '44px', cursor: 'pointer' }} onClick={facebookLogin} />
              </a>
              <a href='#' onClick={googleLogin}>
                <img src={'/images/GoogleIcon.png'} onClick={googleLogin} className={styles.circleIcon} />
              </a>
              <a href='#' onClick={microsoftLogin}>
                <img src={microsoft} className={styles.circleIcon} onClick={microsoftLogin} />
              </a>
              {/* <a href='#'>
                <img src={apple} />
              </a> */}
            </div>
          </div>
        </div>
        {
          signup ? <SignupBottom /> : <SignInBottom />
        }
      </div>
    }
  </>)
}

export default LoginPage
