import axios from 'axios'
import { facebookLoginUrl, googleLoginUrl, microsoftLoginUrl, googleLoginCallbackUrl, facebookLoginCallbackUrl, microsoftLoginCallbackUrl } from '../api'

export const FacebookLoginAction = () => async (dispatch) => {
    dispatch({ type: 'SHOW_LOGIN_LOADER' })
    await axios
        .get(facebookLoginUrl())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_LOGIN_LOADER' })

            dispatch({ type: 'SET_LOGIN_RESPONSE', payload: x })

            if (!x.error) {
                window.location.href = x.data

            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_LOGIN_LOADER' })

            dispatch({ type: 'SET_LOGIN_RESPONSE', payload: { error: true } })

        })
}
export const GoogleLoginAction = () => async (dispatch) => {
    dispatch({ type: 'SHOW_LOGIN_LOADER' })
    await axios
        .get(googleLoginUrl())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_LOGIN_LOADER' })
            dispatch({ type: 'SET_LOGIN_RESPONSE', payload: x })
            if (!x.error) {
                window.location.href = x.data


            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_LOGIN_LOADER' })
            dispatch({ type: 'SET_LOGIN_RESPONSE', payload: { error: true } })
            console.log("er", error)
        })
}
export const MicrosoftLoginAction = () => async (dispatch) => {
    dispatch({ type: 'SHOW_LOGIN_LOADER' })
    await axios
        .get(microsoftLoginUrl())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_LOGIN_LOADER' })

            dispatch({ type: 'SET_LOGIN_RESPONSE', payload: x })

            if (!x.error) {
                console.log("no error")
                window.location.href = x.data


            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_LOGIN_LOADER' })

            dispatch({ type: 'SET_LOGIN_RESPONSE', payload: { error: true } })

        })
}



export const FacebookLoginCallbackAction = (data) => async (dispatch) => {
    await axios
        .post(facebookLoginCallbackUrl(), data)
        .then(async function ({ data: x }) {
            dispatch({ type: 'FACEBOOK_LOGIN', payload: x })
            if (!x.error) {

            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'FACEBOOK_LOGIN', payload: { error: true } })
            console.log("er", error)
        })
}
export const GoogleLoginCallbackAction = (data) => async (dispatch) => {
    await axios
        .post(googleLoginCallbackUrl(), data)
        .then(async function ({ data: x }) {
            dispatch({ type: 'GOOGLE_LOGIN', payload: x })
            if (!x.error) {
            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'GOOGLE_LOGIN', payload: { error: true } })
            console.log("er", error)
        })
}
export const MicrosoftLoginCallbackAction = (data) => async (dispatch) => {
    await axios
        .post(microsoftLoginCallbackUrl(), data)
        .then(async function ({ data: x }) {
            dispatch({ type: 'MICROSOFT_LOGIN', payload: x })
            if (!x.error) {

            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'MICROSOFT_LOGIN', payload: { error: true } })
            console.log("er", error)
        })
}