export function createTransactionData(sNo, asset, value, currency, valueUsd, assetId) {
    return { sNo, asset, value, currency, valueUsd, assetId };
}

export default (
    state = {
        transactionDeleteLoader: false,
        transactionLoader: false,
        assets: [],
        transactionList: [],
        transactionResp: null,
        transactionData: null,
        deleteTransactionResp: null
    },
    action
) => {
    switch (action.type) {
        case 'SET_ASSETS':
            return { ...state, assets: action.payload }
        case 'SET_TRANSACTION_LIST':
            return { ...state, transactionList: action.payload }
        case 'ADD_TRANSACTION_RESPONSE':
            return { ...state, transactionResp: action.payload }
        case 'DELETE_TRANSACTION_RESPONSE':
            return { ...state, deleteTransactionResp: action.payload }
        case 'SET_TRANSACTION_DATA':
            return { ...state, transactionData: action.payload }
        case 'SHOW_TRANSACTION_LOADER':
            return { ...state, transactionLoader: true }
        case 'HIDE_TRANSACTION_LOADER':
            return { ...state, transactionLoader: false }
        case 'SHOW_DELETE_TRANSACTION_LOADER':
            return { ...state, transactionDeleteLoader: true }
        case 'HIDE_DELETE_TRANSACTION_LOADER':
            return { ...state, transactionDeleteLoader: false }
        default:
            return state;
    }
};
