import axios from 'axios'
import { DateFormat, getToken } from '../../../settings'
import { addAssetUrl, listAssetUrl, getAssetByIdUrl, getTransactionOfAssetUrl, editAssetUrl, deleteAssetUrl, listAssetValuationUrl, editAssetValuationUrl, deleteAssetValuationUrl, listAssetValuationByAssetUrl, addAssetValuationUrl } from '../api'
import { createAssetData } from '../reducers/assetsReducer'
import _ from 'lodash'
import moment from 'moment'
import Async from 'async'
import { listAggregationDataAction } from '../../dashboard/actions/dashboardAction'

export const AddAssetAction = (data) => async (dispatch) => {
    dispatch({ type: 'SHOW_ASSET_LOADER' })
    await axios
        .post(addAssetUrl(), data, getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            dispatch({ type: 'ADD_ASSET_RESPONSE', payload: x })
            if (!x.error) {
                dispatch(listAssetAction())
            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            dispatch({ type: 'ADD_ASSET_RESPONSE', payload: { error: true } })

            // console.log("er", error)
        })
}

export const listAssetAction = () => async (dispatch) => {
    // dispatch({ type: 'SHOW_ASSET_LOADER' })
    await axios
        .get(listAssetUrl(), getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            if (!x.error) {
                let list = x.data['assets'] || []
                dispatch({ type: 'SET_ASSET_LIST', payload: list })
            } else {
                dispatch({ type: 'SET_ASSET_LIST', payload: [] })
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            // console.log("er", error)
        })
}

export const listTransactionsOfAssetAction = (data, assetLists = []) => async (dispatch) => {
    dispatch({ type: 'SHOW_ASSET_LOADER' })
    await axios
        .post(getTransactionOfAssetUrl(), data, getToken())
        .then(function (resp) {
            const { data: x } = resp
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            if (resp.status == 404 || resp.status == 500) {
                dispatch({ type: 'SET_ASSET_TRANSACTION_LIST', payload: [] })
                return false
            }
            if (!x.error) {
                let list = x.data['transactions_list'] || []
                list = _.orderBy(list, function (o) { return moment(o.date); }, 'asc');
                list = _.map(list, (value, index) => {
                    let asset = _.find(assetLists, (elem) => elem.id === value['asset_id'])
                    if (asset) {
                        value = { ...asset, ...value, sNo: index + 1 }
                    }
                    return value
                })
                dispatch({ type: 'SET_ASSET_TRANSACTION_LIST', payload: _.orderBy(list, function (o) { return moment(o.date); }, 'asc') })
            } else {
                dispatch({ type: 'SET_ASSET_TRANSACTION_LIST', payload: [] })
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            // console.log("er", error)
        })
}

export const getAssetByIdAction = (data) => async (dispatch) => {
    dispatch({ type: 'SHOW_ASSET_LOADER' })
    await axios
        .post(getAssetByIdUrl(), data, getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            if (!x.error) {
                dispatch({ type: 'SET_ASSET_DATA', payload: x.data })
            } else {
                dispatch({ type: 'SET_ASSET_DATA', payload: null })
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            // console.log("er", error)
        })
}

export const EditAssetAction = (data) => async (dispatch) => {
    dispatch({ type: 'SHOW_ASSET_LOADER' })
    await axios
        .post(editAssetUrl(), data, getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            dispatch({ type: 'ADD_ASSET_RESPONSE', payload: x })
            if (!x.error) {
                dispatch(listAssetAction())
            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })

            // console.log("er", error)
        })
}

export const DeleteAssetAction = (data) => async (dispatch) => {
    dispatch({ type: 'SHOW_ASSET_LOADER' })
    await axios
        .post(deleteAssetUrl(), data, getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'DELETE_ASSET_RESPONSE', payload: x })
            if (!x.error) {
                dispatch(listAssetAction())
            } else {
                dispatch({ type: 'HIDE_ASSET_LOADER' })
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_LOADER' })
            // console.log("er", error)
        })
}

export const listAssetValuationAction = (id, assetLists) => async (dispatch) => {
    dispatch({ type: 'SHOW_ASSET_VALUATION_LOADER' })
    await axios
        .get(listAssetValuationUrl(), getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_ASSET_VALUATION_LOADER' })
            if (!x.error) {
                let tempList = _.values(_.values(x.data)[0] || {}) || []
                let list = _.flatMap(tempList, (e) => e[0])
                list = _.map(list, (value, index) => {
                    if (!value.isActive) {
                        return false;
                    }
                    let asset = _.find(assetLists, (elem) => elem.id === value['asset_id'])
                    if (asset) {
                        value = { ...asset, ...value, sNo: index + 1 }
                    }
                    return value
                })
                list = _.filter(list, (elem) => elem)
                dispatch({ type: 'SET_ASSET_VALUATION_LIST', payload: _.orderBy(list, function (o) { return moment(o.valid_from); }, 'asc') })
            } else {
                dispatch({ type: 'SET_ASSET_VALUATION_LIST', payload: [] })
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_VALUATION_LOADER' })
            // console.log("er", error)
        })
}

export const EditAssetValuationAction = (data, assetList = []) => async (dispatch) => {
    dispatch({ type: 'SHOW_ASSET_VALUATION_LOADER' })
    await axios
        .post(editAssetValuationUrl(), data, getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_ASSET_VALUATION_LOADER' })
            dispatch({ type: 'ADD_ASSET_VALUATION_RESPONSE', payload: x })
            if (!x.error) {
                dispatch(listAssetAction())
                dispatch(listAggregationDataAction(assetList))
            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_VALUATION_LOADER' })

            // console.log("er", error)
        })
}

export const DeleteAssetValuationAction = (data) => async (dispatch) => {
    dispatch({ type: 'SHOW_ASSET_VALUATION_LOADER' })
    await axios
        .post(deleteAssetValuationUrl(), data, getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'DELETE_ASSET_VALUATION_RESPONSE', payload: x })
            if (!x.error) {
                // dispatch(listAssetValuationByAssetAction(data))
            } else {
                dispatch({ type: 'HIDE_ASSET_VALUATION_LOADER' })
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_VALUATION_LOADER' })
            // console.log("er", error)
        })
}

export const listAssetValuationByAssetAction = (data, assetLists) => async (dispatch) => {
    dispatch({ type: 'SHOW_ASSET_VALUATION_LOADER' })
    await axios
        .post(listAssetValuationByAssetUrl(), data, getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_ASSET_VALUATION_LOADER' })
            if (!x.error) {
                let list = _.values(_.values(x.data)[0] || [])
                list = _.map(list, (value, index) => {
                    if (!value.isActive) {
                        return false;
                    }
                    let asset = _.find(assetLists, (elem) => elem.id === value['asset_id'])
                    if (asset) {
                        value = { ...asset, ...value }
                    }
                    return value
                })
                list = _.filter(list, (elem) => elem)
                dispatch({ type: 'SET_ASSET_VALUATION_LIST', payload: list })
            } else {
                dispatch({ type: 'SET_ASSET_VALUATION_LIST', payload: [] })
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ASSET_VALUATION_LOADER' })
            // console.log("er", error)
        })
}

export const AddAssetValuationAction = (data, assetList) => async (dispatch) => {
    dispatch({ type: 'SHOW_ADD_ASSET_VALUATION_LOADER' })
    await axios
        .post(addAssetValuationUrl(), data, getToken())
        .then(async function ({ data: x }) {
            dispatch({ type: 'HIDE_ADD_ASSET_VALUATION_LOADER' })
            dispatch({ type: 'ADD_ASSET_VALUATION_RESPONSE', payload: x })
            if (!x.error) {
                dispatch(listAssetAction())
                dispatch(listAggregationDataAction(assetList))
            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_ADD_ASSET_VALUATION_LOADER' })
            dispatch({ type: 'ADD_ASSET_VALUATION_RESPONSE', payload: { error: true } })

            // console.log("er", error)
        })
}