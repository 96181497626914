import { apiUrl } from "../../../settings";

export const googleLoginUrl = () => {
    return apiUrl + '/login/google';
};
export const facebookLoginUrl = () => {
    return apiUrl + '/login/fb';
};
export const microsoftLoginUrl = () => {
    return apiUrl + '/login/ms';
};

export const googleLoginCallbackUrl = () => {
    return apiUrl + '/login/google/callback';
};
export const facebookLoginCallbackUrl = () => {
    return apiUrl + '/login/fb/callback';
};
export const microsoftLoginCallbackUrl = () => {
    return apiUrl + '/login/ms/callback';
};