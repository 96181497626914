export function createAssetData(date, transaction, amount, currency) {
    return { date, transaction, amount, currency };
}
export default (
    state = {
        assetLoader: false,
        assetValuationLoader: false,
        assetTransactionList: [],
        assetList: [],
        assetValuationList: [],
        chartData: [{
            data: []
        }],
        assetsClass: [
            {
                value: 'broker',
                label: 'Broker Account'
            },
            {
                value: 'investmentFund',
                label: 'Investment Fund'
            },
            {
                value: 'pension',
                label: 'Pension Fund'
            },
            {
                value: 'realEstate',
                label: 'Real Estate'
            },
            {
                value: 'privateEquity',
                label: 'Private Equity Holding'
            },
            {
                value: 'preciousMetal',
                label: 'Precious Metal'
            },
            {
                value: 'jewellery',
                label: 'Jewellery and Watches'
            },
            {
                value: 'crypto',
                label: 'Cryptocurrency'
            },
            {
                value: 'other',
                label: 'Other'
            }
        ],
        addAssetResponse: null,
        deleteAssetResponse: null,
        deleteAssetValuationResponse: null,
        assetData: null,
        addAssetValuationLoader: false,
        addAssetValuationResponse: null
    },
    action
) => {
    switch (action.type) {
        case "SET_ASSET_LIST":
            return { ...state, assetList: action.payload };
        case "SET_ASSET_VALUATION_LIST":
            return { ...state, assetValuationList: action.payload };
        case "SET_ASSET_TRANSACTION_LIST":
            return { ...state, assetTransactionList: action.payload };
        case "SET_ASSET_DATA":
            return { ...state, assetData: action.payload };
        case 'ADD_ASSET_RESPONSE':
            return { ...state, addAssetResponse: action.payload }
        case 'ADD_ASSET_VALUATION_RESPONSE':
            return { ...state, addAssetValuationResponse: action.payload }
        case 'DELETE_ASSET_RESPONSE':
            return { ...state, deleteAssetResponse: action.payload }
        case 'SET_ASSET_CHART_DATA':
            return { ...state, chartData: action.payload }
        case 'DELETE_ASSET_VALUATION_RESPONSE':
            return { ...state, deleteAssetValuationResponse: action.payload }
        case 'SHOW_ASSET_LOADER':
            return { ...state, assetLoader: true }
        case 'HIDE_ASSET_LOADER':
            return { ...state, assetLoader: false }
        case 'SHOW_ASSET_VALUATION_LOADER':
            return { ...state, assetValuationLoader: true }
        case 'HIDE_ASSET_VALUATION_LOADER':
            return { ...state, assetValuationLoader: false }
        case 'SHOW_ADD_ASSET_VALUATION_LOADER':
            return { ...state, addAssetValuationLoader: true }
        case 'HIDE_ADD_ASSET_VALUATION_LOADER':
            return { ...state, addAssetValuationLoader: false }
        default:
            return state;
    }
};
