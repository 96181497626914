export default (
    state = {
        loginLoader: false,
        loginType: null,
        loginPayload: {},
        loginResponse: {}
    },
    action
) => {
    switch (action.type) {
        case "FACEBOOK_LOGIN":
            return { ...state, loginPayload: action.payload, loginType: 'FACEBOOK' }
        case "GOOGLE_LOGIN":
            return { ...state, loginPayload: action.payload, loginType: 'GOOGLE' }
        case "MICROSOFT_LOGIN":
            return { ...state, loginPayload: action.payload, loginType: 'MICROSOFT' }
        case "SET_LOGIN_RESPONSE":
            return { ...state, loginResponse: action.payload }
        case "SHOW_LOGIN_LOADER":
            return { ...state, loginLoader: true }
        case "HIDE_LOGIN_LOADER":
            return { ...state, loginLoader: false }
        default:
            return state;
    }
};
