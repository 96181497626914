import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MicrosoftLoginCallbackAction } from '../actions';
import { loginCallBackOriginUrl } from '../../../settings'
import { useSnackbar } from 'notistack';
import jwt_decode from 'jwt-decode'

export default function Dashboard() {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { loginPayload, loginType } = useSelector((state) => ({
        loginType: state.login.loginType,
        loginPayload: state.login.loginPayload
    }))

    useEffect(() => {
        dispatch(MicrosoftLoginCallbackAction({ url: `${loginCallBackOriginUrl}${window.location.pathname}${window.location.search}` }))
    }, [])

    useEffect(() => {
        if (loginPayload && loginPayload.success && loginPayload.data) {
            let token = loginPayload.data.autho_token
            let decodedUser = jwt_decode(token)

            if (decodedUser && decodedUser['user-data']) {
                dispatch({ type: 'SET_CURRENT_USER', user: decodedUser['user-data'] })
            }
            dispatch({ type: 'SET_AUTH_TOKEN', token: loginPayload.data.autho_token })
            dispatch(push('/dashboard'))
        }
        else if (loginPayload && loginPayload.error) {
            enqueueSnackbar('Login failed.', {
                variant: 'error',
            });
            dispatch({ type: 'MICROSOFT_LOGIN', payload: null })
            dispatch(push('/login'))
        }
    }, [loginType, loginPayload])

    return (
        <div>
            Redirecting ...
        </div>
    );
}
