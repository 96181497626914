import axios from 'axios'
import { DateFormat, getToken } from '../../../settings'
import { assetAggregationUrl, getUserDetailsUrl } from '../api/dashboardApi'
import _ from 'lodash'
import moment from 'moment'

export const listAggregationDataAction = (assetLists = [], chartMinMonth) => async (dispatch) => {
    dispatch({ type: 'SHOW_DASHBOARD_LOADER' })
    await axios
        .get(assetAggregationUrl(), getToken())
        .then(async function ({ data: x }) {
            if (!x.error) {
                let list = _.cloneDeep(x.data) || {}
                let currentMonth = moment().month()
                let newSeries = {},
                    assetChartData = {}
                let totalNetWorth = list['total_net_worth'] || 0,
                    totalTWR = list['Total TWR'] || {},
                    totalXRR = list['Total XRR'] || {},
                    latestValuationDate = list['Latest_valuation_date'] || {},
                    combinedXRR = list['Grand Combined XRR'] || 0,
                    combinedTWR = list['Grand Combined TWR'] || 0,
                    currentValue = {},
                    investedAmount = list['invested_amount'] || {},
                    totalTransaction = list['Total_transaction'] || 0

                let summaryData = {
                    totalXRR,
                    totalTWR,
                    totalNetWorth,
                    latestValuationDate,
                    combinedXRR,
                    combinedTWR,
                    currentValue,
                    investedAmount,
                    totalTransaction
                }

                _.forEach(list, (value, year) => {
                    if (isNaN(parseInt(year))) {
                        return false
                    }
                    newSeries[year] = {}
                    if (year == moment().year()) {
                        currentValue = value['current_value'] || {}
                        summaryData.currentValue = currentValue
                    }
                    let allMonths = _.keys(value)
                    let minMonth = allMonths[0]
                    if (chartMinMonth === undefined || parseInt(chartMinMonth) > parseInt(minMonth)) {
                        chartMinMonth = minMonth
                        dispatch({ type: 'SET_CHART_MIN_MONTH', payload: minMonth })
                    }
                    _.forEach(value, (val, month) => {
                        if (isNaN(parseInt(month))) {
                            return false
                        }
                        else if (parseInt(month) > currentMonth + 1) {
                            return false;
                        }
                        else {
                            _.forEach(val, (as, key) => {
                                if (isNaN(parseInt(key))) {
                                    return false
                                }
                                else {
                                    if (!assetChartData[key]) {
                                        assetChartData[key] = { data: [], name: as['asset_id'] }
                                    }
                                    assetChartData[key].data.push([moment(as['valid_from']).set({ month: month - 1, year }).valueOf(), as['current_value'].toFixed(2)])
                                    let asset = _.find(assetLists, (elem) => elem.id === as['asset_id'])
                                    if (!asset) {
                                        return false
                                    }
                                    as = { ...asset, ...as }
                                    if (!newSeries[year][as['asset_id']]) {
                                        newSeries[year][as['asset_id']] = {
                                            id: as['asset_id'],
                                            name: as['name'],
                                            data: Array(13 - chartMinMonth - (11 - currentMonth)).fill(0)
                                        }
                                    }
                                    newSeries[year][as['asset_id']].data[month - chartMinMonth] = (as.current_value).toFixed(2)
                                }
                            })
                        }
                    })
                })
                dispatch({ type: 'SET_SUMMARY_DATA', payload: summaryData })

                dispatch({ type: 'SET_ASSET_CHART_DATA', payload: assetChartData })
                dispatch({ type: 'SET_DASHBOARD_CHART_DATA', payload: newSeries })
                dispatch({ type: 'HIDE_DASHBOARD_LOADER' })

            } else {
                let totalTWR = {},
                    totalXRR = {},
                    latestValuationDate = {},
                    combinedXRR = 0,
                    combinedTWR = 0,
                    currentValue = {},
                    investedAmount = {},
                    totalTransaction = 0,
                    totalNetWorth = 0

                let summaryData = {
                    totalXRR,
                    totalTWR,
                    totalNetWorth,
                    latestValuationDate,
                    combinedXRR,
                    combinedTWR,
                    currentValue,
                    investedAmount,
                    totalTransaction
                }
                dispatch({ type: 'SET_SUMMARY_DATA', payload: summaryData })
                dispatch({ type: 'SET_DASHBOARD_CHART_DATA', payload: {} })
                dispatch({ type: 'SET_ASSET_CHART_DATA', payload: {} })
                dispatch({ type: 'HIDE_DASHBOARD_LOADER' })

                console.log("Err")
            }
        })
        .catch(function (error) {
            dispatch({ type: 'HIDE_DASHBOARD_LOADER' })
            console.log("er", error)
        })
}

export const GetUserDetailAction = (data) => async (dispatch) => {
    await axios
        .get(getUserDetailsUrl(), getToken())
        .then(async function ({ data: x }) {
            if (!x.error) {
                dispatch({ type: 'SET_CURRENT_USER', user: x.data })
            } else {
                console.log("Err")
            }
        })
        .catch(function (error) {
            // console.log("er", error)
        })
}